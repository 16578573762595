import React, { useState } from 'react';
import { Typography, Grid, IconButton, Button, Tabs, Tab } from '@mui/material';
import { useEffect } from 'react';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService } from '../Utils/ApiService';
import { Delete } from '@mui/icons-material';
import { domains } from '../Utils/Mutations';

interface IHistoryRecord {
  id: number;
  searchText: string;
  domain: string;
}

export default function SearchHistory() {
  const [searchHistory, setSearchHistory] = useState<IHistoryRecord[]>();
  const [domainTab, setDomainTab] = useState(0);

  const layout = useLayout();

  useEffect(
    () => {
      catalogService.get('/products/searchHistory', undefined, {
        success: setSearchHistory,
        error: () => layout.error('Při načítání historie došlo k chybě'),
      });
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Typography variant="h4">Historie vyhledávání</Typography>
        <span style={{ flexGrow: 1 }}></span>
        <Button
          color="secondary"
          onClick={() =>
            layout.deleteConfirm('Opravdu chcete vymazat celou historii vyhledávání?', '', () =>
              catalogService.delete('/products/searchHistory/all', {}, null, {
                success: () => setSearchHistory([]),
                error: () => layout.error('Při mazání došlo k chybě'),
              })
            )
          }
        >
          Smazat vše
        </Button>
      </div>
      <br />
      <Tabs color="primary" value={domainTab} onChange={(_, val) => setDomainTab(val)}>
        {domains.map((d) => (
          <Tab key={d} label={d} />
        ))}
      </Tabs>
      <br />
      <Grid container>
        {searchHistory
          ?.filter((h) => h.domain === domains[domainTab])
          ?.map((h) => (
            <Grid item xs={2}>
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <IconButton
                    onClick={() =>
                      catalogService.delete('/products/searchHistory', { id: h.id }, null, {
                        success: () => setSearchHistory(searchHistory.filter((hh) => hh.id !== h.id)),
                        error: () => layout.error('Při mazání došlo k chybě'),
                      })
                    }
                    aria-label="delete"
                    size="large"
                  >
                    <Delete color="error" />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={10}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(`https://${h.domain}/offer/search/${h.searchText}#notrack`);
                    catalogService.delete('/products/searchHistory', { id: h.id }, null, {
                      success: () => setSearchHistory(searchHistory.filter((hh) => hh.id !== h.id)),
                      error: () => layout.error('Při mazání došlo k chybě'),
                    });
                  }}
                >
                  <Typography noWrap>{h.searchText}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </>
  );
}
