import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  Checkbox,
} from '@mui/material';
import { Distributor, OpenningHoursException, PickupPlace } from '@magistrmartin/eshop-frontend-shared';
import { useLayout } from '../Layout/LayoutContext';
import { ordersService } from '../Utils/ApiService';
import localeCz from 'date-fns/locale/cs';
import { Delete } from '@mui/icons-material';
import { formatters } from '../Utils/Formaters';
import { reducers } from '../Utils/Reducers';
import { DatePicker } from '@mui/x-date-pickers';

const emptyDistributor: Distributor = {
  isPrimary: false,
  name: '',
  customerNumber: '',
  password: '',
};

interface IProps {
  id: number;
  hideAdminFields?: boolean;
}

if (localeCz && localeCz.options) {
  localeCz.options.weekStartsOn = 1;
}

export default function EditPharma({ id, hideAdminFields }: IProps) {
  const defaultOHException: OpenningHoursException = {
    date: new Date(),
    placeId: id,
  };

  const showAdmin = hideAdminFields !== true;

  const [pharma, setPharma] = useState<PickupPlace | undefined>(undefined);
  const [newOHException, setNewOHException] = useState<OpenningHoursException>({ ...defaultOHException });
  const [newDistributor, setNewDistributor] = useState<Distributor>(emptyDistributor);

  const layout = useLayout();

  useEffect(() => {
    ordersService.get(
      '/infrastructure/pickupPlace',
      { id: id },
      {
        success: setPharma,
        error: () => layout.error('Při načítání informací o lékárně došlo k chybě.'),
      }
    );
    // eslint-disable-next-line
  }, [id]);

  return (
    (pharma && (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Informace o lékárně</Typography>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  value={pharma.name}
                  onChange={(e) => setPharma({ ...pharma, name: e.target.value })}
                  label="Název lékárny"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  variant="standard"
                  value={pharma.street}
                  onChange={(e) => setPharma({ ...pharma, street: e.target.value })}
                  label="Ulice"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="standard"
                  value={pharma.houseNum}
                  onChange={(e) => setPharma({ ...pharma, houseNum: e.target.value })}
                  label="ČP"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="standard"
                  value={pharma.zip}
                  onChange={(e) => setPharma({ ...pharma, zip: formatters.ZIPFormater(e.target.value) })}
                  label="PSČ"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  variant="standard"
                  value={pharma.city}
                  onChange={(e) => setPharma({ ...pharma, city: e.target.value })}
                  label="Město"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="standard"
                  value={pharma.phone}
                  onChange={(e) => setPharma({ ...pharma, phone: formatters.PhoneFormater(e.target.value) })}
                  label="Telefon"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="standard"
                  value={pharma.mail}
                  onChange={(e) => setPharma({ ...pharma, mail: e.target.value })}
                  label="Email"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="standard"
                  type="number"
                  value={pharma.longitude}
                  onChange={(e) => setPharma({ ...pharma, longitude: parseFloat(e.target.value) })}
                  label="Zeměpisná délka"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="standard"
                  type="number"
                  value={pharma.latitude}
                  onChange={(e) => setPharma({ ...pharma, latitude: parseFloat(e.target.value) })}
                  label="Zeměpisná šířka"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Otevírací doba</Typography>
            <br />
            <Grid container spacing={3}>
              {pharma.openningHoursList.map((oh) => (
                <Grid item key={oh.id} xs={12} md={6}>
                  <TextField
                    variant="standard"
                    label={oh.dayName}
                    value={oh.value}
                    onChange={(e) =>
                      setPharma({
                        ...pharma,
                        openningHoursList: pharma.openningHoursList.map((o) =>
                          o.id === oh.id ? { ...o, value: e.target.value } : o
                        ),
                      })
                    }
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Výjimky z otevírací doby</Typography>
            <br />
            <List>
              {pharma.openningHoursExceptions
                .sort((a, b) => new Date(a.date || '').getTime() - new Date(b.date || '').getTime())
                .map((ohe) => (
                  <ListItem key={ohe.id}>
                    <ListItemText>
                      {new Date(ohe.date || '').toLocaleDateString()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {ohe.value || 'Zavřeno'}
                    </ListItemText>
                    <ListItemIcon>
                      <IconButton
                        aria-label="delete"
                        onClick={() =>
                          layout.deleteConfirm(
                            'Smazat výjimu z otevírací doby',
                            'Opravdu chcete smazat tuto výjimku z otevírací doby?',
                            () =>
                              ordersService.delete('/infrastructure/openingHoursException', { id: ohe.id }, null, {
                                success: () =>
                                  setPharma({
                                    ...pharma,
                                    openningHoursExceptions: pharma.openningHoursExceptions.filter(
                                      (o) => o.id !== ohe.id
                                    ),
                                  }),
                                error: () => layout.error('Při mazání došlo k chybě'),
                              })
                          )
                        }
                        size="large"
                      >
                        <Delete color="error" />
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                ))}
            </List>
            <Grid container spacing={3}>
              <Grid item xs={12} md={5}>
                <DatePicker
                  inputFormat="yyyy-MM-dd"
                  label="Datum"
                  value={newOHException.date || null}
                  onChange={(date) => setNewOHException({ ...newOHException, date: date || undefined })}
                  renderInput={(p) => <TextField fullWidth {...p} />}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  variant="standard"
                  label="Nový čas"
                  fullWidth
                  value={newOHException.value || ''}
                  onChange={(e) => setNewOHException({ ...newOHException, value: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  color="primary"
                  onClick={() =>
                    newOHException.date &&
                    ordersService.post('/infrastructure/openingHoursException', {}, newOHException, {
                      success: (id: number) =>
                        setPharma({
                          ...pharma,
                          openningHoursExceptions: [...pharma.openningHoursExceptions, { ...newOHException, id: id }],
                        }),
                      error: () => layout.error('Při ukládání došlo k chybě'),
                    })
                  }
                >
                  Přidat
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Fakturační údaje</Typography>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  value={pharma.internalName}
                  onChange={(e) => setPharma({ ...pharma, internalName: e.target.value })}
                  label="Interní název odběratele"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  value={pharma.distributionName}
                  onChange={(e) => setPharma({ ...pharma, distributionName: e.target.value })}
                  label="Distribuční název odběratele"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  variant="standard"
                  value={pharma.invStreet}
                  onChange={(e) => setPharma({ ...pharma, invStreet: e.target.value })}
                  label="Ulice"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="standard"
                  value={pharma.invHouseNum}
                  onChange={(e) => setPharma({ ...pharma, invHouseNum: e.target.value })}
                  label="ČP"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="standard"
                  value={pharma.invZip}
                  onChange={(e) => setPharma({ ...pharma, invZip: formatters.ZIPFormater(e.target.value) })}
                  label="PSČ"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  variant="standard"
                  value={pharma.invCity}
                  onChange={(e) => setPharma({ ...pharma, invCity: e.target.value })}
                  label="Město"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="standard"
                  value={pharma.companyId}
                  onChange={(e) => setPharma({ ...pharma, companyId: e.target.value })}
                  label="IČ"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="standard"
                  value={pharma.taxId}
                  onChange={(e) => setPharma({ ...pharma, taxId: e.target.value })}
                  label="DIČ"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          {showAdmin && (
            <Grid item xs={12} md={4}>
              <Typography variant="h6">Administrátorské položky</Typography>
              <br />
              <TextField
                variant="standard"
                label="Cesta pro import z Lekisu"
                fullWidth
                value={pharma.lekisImportPath}
                onChange={(e) => setPharma({ ...pharma, lekisImportPath: e.target.value })}
              />
              <br />
              <br />
              <TextField
                variant="standard"
                label="Marže v MM Distribuci"
                fullWidth
                value={pharma.centralDistributionMargins}
                onChange={(e) => setPharma({ ...pharma, centralDistributionMargins: e.target.value as any })}
              />
              <br />
              <br />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  color="primary"
                  checked={pharma.countToDistribution}
                  onClick={() => setPharma({ ...pharma, countToDistribution: !pharma.countToDistribution })}
                />{' '}
                <Typography>Započítávat prodejnosti lékárny do MM Distribuce</Typography>
              </div>
              <Typography variant="h6">Dodavatelé - distributoři</Typography>
              <Grid container spacing={3}>
                {pharma.distributors.map((d, i) => (
                  <React.Fragment
                    key={d.id || i + pharma.distributors.map((dd) => dd.id || 0).reduce(reducers.max, 0) + 1}
                  >
                    <Grid item xs={1}>
                      <Checkbox
                        checked={d.isPrimary}
                        onClick={() =>
                          setPharma({
                            ...pharma,
                            distributors: pharma.distributors.map((dd) => ({
                              ...dd,
                              isPrimary: dd.id === d.id && !d.isPrimary,
                            })),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="standard"
                        label="Název"
                        fullWidth
                        value={d.name}
                        onChange={(e) =>
                          setPharma({
                            ...pharma,
                            distributors: pharma.distributors.map((dd) =>
                              dd.id === d.id ? { ...dd, name: e.target.value } : dd
                            ),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        variant="standard"
                        label="Zákaznické číslo"
                        fullWidth
                        value={d.customerNumber}
                        onChange={(e) =>
                          setPharma({
                            ...pharma,
                            distributors: pharma.distributors.map((dd) =>
                              dd.id === d.id ? { ...dd, customerNumber: e.target.value } : dd
                            ),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        variant="standard"
                        label="Heslo"
                        fullWidth
                        value={'*'.repeat(d.password?.length || 0)}
                        onChange={(e) =>
                          setPharma({
                            ...pharma,
                            distributors: pharma.distributors.map((dd) =>
                              dd.id === d.id ? { ...dd, password: e.target.value } : dd
                            ),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Delete
                        color="error"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setPharma({ ...pharma, distributors: pharma.distributors.filter((dd) => dd.id !== d.id) })
                        }
                      />
                    </Grid>
                  </React.Fragment>
                ))}
                <Grid item xs={4}>
                  <TextField
                    variant="standard"
                    label="Název"
                    fullWidth
                    value={newDistributor.name}
                    onChange={(e) => setNewDistributor({ ...newDistributor, name: e.target.value })}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="standard"
                    label="Zákaznické číslo"
                    fullWidth
                    value={newDistributor.customerNumber}
                    onChange={(e) => setNewDistributor({ ...newDistributor, customerNumber: e.target.value })}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="standard"
                    label="Heslo"
                    fullWidth
                    value={'*'.repeat(newDistributor.password?.length || 0)}
                    onChange={(e) => setNewDistributor({ ...newDistributor, password: e.target.value })}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={newDistributor.name.length === 0}
                    onClick={() => {
                      setPharma({ ...pharma, distributors: [...pharma.distributors, { ...newDistributor }] });
                      setNewDistributor(emptyDistributor);
                    }}
                  >
                    Přidat
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            ordersService.post('/infrastructure/pickupPlace', { edit: true }, pharma, {
              success: () => layout.success('Změny úspěšně uloženy'),
              error: () => layout.error('Při ukládání změn došlo k chybě'),
            })
          }
        >
          Uložit změny
        </Button>
      </>
    )) || <></>
  );
}
