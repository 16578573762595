import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';

interface IProps {
  data: any[];
  addRow: (newData: string) => void;
  rowGenerator: (e: any, i: number) => JSX.Element;
  columns?: 1 | 2 | 3 | 4 | 6 | 12;
  title: string;
}

type numsTotwelve = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export default function CatalogConfigCard({ data, addRow, rowGenerator, columns, title }: IProps) {
  const [newRow, setNewRow] = useState('');

  if (columns === undefined) columns = 1;

  return (
    <Card>
      <CardHeader title={title} subheader={data.length} />
      <CardContent>
        <Grid container spacing={3}>
          {data.map((d, i) => (
            <Grid key={d.id} item xs={12} md={(12 / (columns || 1)) as numsTotwelve}>
              {rowGenerator(d, i)}
            </Grid>
          ))}
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Nový záznam"
              value={newRow}
              onChange={(e) => setNewRow(e.target.value)}
              onBlur={() => {
                if (newRow.length > 0) {
                  addRow(newRow);
                  setNewRow('');
                }
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
