import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { sub } from 'date-fns';
import React from 'react';

export default function ScheduledJobs() {
  const jobs = [
    {
      job: 'Update stavu skladu z Lekisu',
      time: 'Každý den 5 minut po každé celé hodině mezi 6. a 23.',
      details: '',
      link: '',
      method: 'POST',
    },
    {
      job: 'Přepočítání relevančního skóre pro řazení produktů',
      time: 'Každý den 5 minut po každé celé hodině mezi 6. a 23.',
      details: '',
      link: 'http://localhost:8432/products/updateRelevanceScores?weeksCount=8',
      method: 'POST',
    },
    {
      job: 'Automatický update statusů objednávek',
      time: 'Každých 5 minut',
      details: '',
      link: 'https://api.magistrmartin.cz/orders/updateStates',
      method: 'POST',
    },
    {
      job: 'Načtení dat z Pharmosu',
      time: 'Každé pondělí a středu ve 3:00',
      details:
        'Neprovádí se první 3 dny všech lichých měsíců a poslední 3 dny všech sudých měsíců. Obvykle trvá několik hodin.',
      link: 'https://api.magistrmartin.cz/catalog/pharmos/scan',
      method: 'POST',
    },
    {
      job: 'Načtení dat z Phramdata',
      time: 'Každé úterý a čtvrtek ve 3:00',
      details: 'Obvykle trvá řádově v desítkách minut',
      link: 'http://localhost:8432/pharmdata/scan',
      method: 'POST',
    },
    {
      job: 'Automatická úprava cen',
      time: 'Každé pondělí a středu v 8:00',
      details: '',
      link: 'https://api.magistrmartin.cz/catalog/pharmos/updatePrices',
      method: 'POST',
    },
    {
      job: 'Načtení cen z Heuréky',
      time: 'Každý den v 1:00',
      details: 'Obvykle trvá řádově v desítkách minut',
      link: 'http://localhost:8432/heureka/scanPrices',
      method: 'POST',
    },
    {
      job: 'Import historie prodejností z Lekisu',
      time: 'Každé pondělí v 5:00',
      details: '',
      link: 'https://api.magistrmartin.cz/statistics/importData',
      method: 'POST',
    },
    {
      job: 'Odeslání notifikace na produkty z distribuce, které nejsou spárovány s lekisem',
      time: 'Každé pondělí v 11:00',
      details: '',
      link: 'https://api.magistrmartin.cz/catalog/stock/notifyUnpairedDistributionProducts',
      method: 'POST',
    },
    {
      job: 'Odeslání notifikace ohledně nedodaných produktů zdarma',
      time: 'Každé pondělí ve 12:00',
      details: '',
      link: 'http://localhost:8514/stats/notifyExpectedFreeItemsDeliver',
      method: 'POST',
    },
    {
      job: 'Update statusů ze zásilkovny',
      time: 'Kažých 30 minut',
      details: 'Pouze zásilky vytvořené v posledních 8 dnech',
      link: 'http://api.magistrmartin.cz/orders/packeta/updateStates?days=8',
    },
    {
      job: 'Update statusů ze zásilkovny',
      time: 'Každý den v 8:30',
      details: 'Všechny zásilky',
      link: 'http://api.magistrmartin.cz/orders/packeta/updateStates?days=0',
    },
    {
      job: 'Update platby kartou ze zásilkovny',
      time: 'Každý den v 9:15',
      details: '',
      link: 'http://api.magistrmartin.cz/orders/packeta/scanCardPayments',
    },
  ];

  const mails = [
    {
      trigger: '1 nebo více produktů z Pharmos WatchListu je dostupný na pharmmosu',
      subject: 'Magistr martin - jeden nebo více produktů je opět dostupných na phramos cz',
      details: '',
      template: 'watchlistItemsFound.html',
    },
    {
      trigger: 'Každé pondělí v 11:00',
      subject: 'Magistr Martin | Spárování produktů přes PDK',
      details: '',
      template: 'unpairedProductsNotification.html',
    },
    {
      trigger: 'Každé pondělí ve 12:00',
      subject: 'Magistr Martin - nedodané rabaty',
      details: '',
      template: 'freeItemsReminder.html',
    },
    {
      trigger: 'Každý den v 9:30',
      subject: 'MagistrMartin.cz | Recenze Heuréka Ověřeno zákazníky',
      details: '6 dní od objednávky',
      template: 'heurekaReviewReminder.html',
    },
    {
      trigger: 'Hned po vytvoření objednávky',
      subject: 'MagistrMartin.cz | Rekapitulace objednávky',
      details: '',
      template: 'newOrderMailTemplate.html',
    },
    {
      trigger: 'Na lékárnu po vytvoření nové objednávky s osobním odběrem',
      subject: 'Nová zásilka s osobním odběrem',
      details: '',
      template: 'newPersonalOrderToStoreMailTemplate.html',
    },
    {
      trigger: 'Na lékárnu po vytvoření nové rezervace E-receptu',
      subject: 'Magistr Martin Rezervace eReceptu | Nová rezervace',
      details: '',
      template: 'newReservationAlertTemplate.html',
    },
    {
      trigger: 'Přidání nového produktu do seznamu Distribuce doobjednat',
      subject: 'Magistr Martin | Distribuce doobjednat',
      details: '',
      template: 'newToOrderItemMailTemplate.html',
    },
    {
      trigger: 'Při kliknutí na tlačítko u objednávky v administraci',
      subject: 'MagistrMartin.cz | Čekáme na dodání produktu',
      details: '',
      template: 'orderDelayedTemplate.html',
    },
    {
      trigger: 'Po přepnutí objednávky přes zásilkovnu do statusu Zabaleno',
      subject: 'MagistrMartin.cz | Vaše objednávka je zabalena',
      details: '',
      template: 'orderPackedTemplate.html',
    },
    {
      trigger: 'Po přepnutí objednávky s osobním odběrem do statusu Zabaleno',
      subject: 'MagistrMartin.cz | Objednávka připravena k vyzvednutí',
      details: '',
      template: 'orderPreparedForPickupTemplate.html',
    },
    {
      trigger: 'Po zamítnutí objednávky',
      subject: 'MagistrMartin.cz | Zamítnutí objednávky',
      details: '',
      template: 'orderRejectedNotification.html',
    },
    {
      trigger: 'Po označení objednávky jako zaplaceno (ručně i automaticky přes ComGate)',
      subject: 'MagistrMartin.cz | Potvrzení platby',
      details: '',
      template: 'paymentConfirmMailTemplate.html',
    },
    {
      trigger: 'Poté, co se nějaká lékárna zaregistruje do systému',
      subject: 'Magistr Martin | Potvrzení registrace lékárny',
      details: '',
      template: 'ppRegistrationConfirm.html',
    },
    {
      trigger: 'Po vytvoření rezervace E-receptu',
      subject: 'Magistr Martin Rezervace eReceptu | Informace o rezervaci',
      details: '',
      template: 'reservationConfirmMailTemplate.html',
    },
    {
      trigger: 'Při najítí jiných fakturačních údajů na v aresu než v lekisu',
      subject: 'Magistr Martin | Změna fakturačních údajů',
      details: '',
      template: 'invoiceDataChangeNotification.html',
    },
    {
      trigger: '10 minut + 1, 24 a 48 hodin po založení objednávky, která není zaplacená a není na dobírku a klasický převod',
      subject: 'Magistr Martin | Žádáme o úhradu zásilky',
      details: 'Kontrolováno každých 10 minut',
      template: 'paymentReminder.html',
    },
    {
      trigger: '56 hodin po založení objednávky, která není zaplacená a je placena klasickým převodem',
      subject: 'Magistr Martin | Žádáme o úhradu zásilky',
      details: 'Kontrolováno každých 10 minut',
      template: 'paymentReminder.html',
    }
  ];

  return (
    <>
      <Typography variant="h6">Pravidelné činnosti</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Činnost</TableCell>
            <TableCell>Spouštění</TableCell>
            <TableCell>Detaily</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.map((job) => (
            <TableRow key={job.job}>
              <TableCell>{job.job}</TableCell>
              <TableCell>{job.time}</TableCell>
              <TableCell>{job.details}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <br />
      <Typography variant="h6">Emaily</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Trigger</TableCell>
            <TableCell>Předmět</TableCell>
            <TableCell>Detaily</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mails.map((mail) => (
            <TableRow key={mail.template}>
              <TableCell>{mail.trigger}</TableCell>
              <TableCell>{mail.subject}</TableCell>
              <TableCell>{mail.details}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
