import React, { useEffect, useState } from 'react';
import { SupplierDetails } from '../Types/base';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService } from '../Utils/ApiService';
import { Grid, Pagination, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { DatePicker } from '@mui/x-date-pickers';

export default function SupplierDetailsTable() {
  const [allDetails, setAllDetails] = useState<SupplierDetails[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [page, setPage] = useState(0);

  const layout = useLayout();

  const suppliersPerPage = 20;
  const maxPage = Math.ceil(allDetails.length / suppliersPerPage);

  useEffect(
    () => {
      layout.setIsLoading(true);
      catalogService.get(
        'settings/allSuppliersDetails',
        {},
        {
          success: (data: SupplierDetails[]) => {
            setAllDetails(data);
            layout.setIsLoading(false);
          },
          error: () => {
            layout.error('Při načítání dat došlo k chybě');
            layout.setIsLoading(false);
          },
        }
      );
    },
    //eslint-disable-next-line
    []
  );

  useEffect(() => {
    catalogService.get('/products/all', { full: false }, { success: setProducts, error: console.log });
    //eslint-disable-next-line
  }, []);

  const changeDetail = (detail: SupplierDetails, key: keyof SupplierDetails, value: Date | string | null) =>
    catalogService.post(
      'settings/supplierDetails',
      {},
      { ...detail, [key]: value },
      {
        success: () => setAllDetails(allDetails.map((d) => (d.id === detail.id ? { ...d, [key]: value } : d))),
        error: () => layout.error('Při ukládání došlo k chybě'),
      }
    );
  return (
    <>
      <Pagination
        count={maxPage}
        showFirstButton
        showLastButton
        page={page + 1}
        onChange={(_, val) => setPage(val - 1)}
        color="primary"
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Dodavatel</TableCell>
            <TableCell>Reprezentant</TableCell>
            <TableCell>Ecommerce Manager</TableCell>
            <TableCell>Marketing Manager</TableCell>
            <TableCell>Nejlevnější na Heurece</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allDetails
            .slice(page * suppliersPerPage, Math.min(allDetails.length, (page + 1) * suppliersPerPage))
            .map((d) => (
              <React.Fragment key={d.id}>
                <TableRow style={{ borderBottom: 'none' }}>
                  <TableCell style={{ borderBottom: 'none' }}>
                    <b style={{ fontSize: '0.96rem' }}>{d.supplierTag}</b>
                    <br />
                    <span style={{ color: 'grey' }}>
                      {products.filter((p) => p.supplier?.toString() === d.id.toString()).length} Produktů
                    </span>
                    <br />
                    <br />
                    <DatePicker
                      inputFormat="yyyy-MM-dd"
                      label="Aktualizace portfolia"
                      value={d.portfolioUpdate ? new Date(d.portfolioUpdate) : null}
                      onChange={(date) => changeDetail(d, 'portfolioUpdate', date)}
                      renderInput={(p) => <TextField fullWidth {...p} margin="dense" />}
                    />
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="standard"
                          label="Jméno"
                          fullWidth
                          defaultValue={d.representatntName}
                          onBlur={(e) => changeDetail(d, 'representatntName', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="standard"
                          label="Příjmení"
                          fullWidth
                          defaultValue={d.representantSurname}
                          onBlur={(e) => changeDetail(d, 'representantSurname', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="standard"
                          label="Telefon"
                          fullWidth
                          defaultValue={d.representantPhone}
                          onBlur={(e) => changeDetail(d, 'representantPhone', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="standard"
                          label="Email"
                          fullWidth
                          defaultValue={d.representantEmail}
                          onBlur={(e) => changeDetail(d, 'representantEmail', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="standard"
                          label="Jméno"
                          fullWidth
                          defaultValue={d.ecoManagerName}
                          onBlur={(e) => changeDetail(d, 'ecoManagerName', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="standard"
                          label="Příjmení"
                          fullWidth
                          defaultValue={d.ecoManagerSurname}
                          onBlur={(e) => changeDetail(d, 'ecoManagerSurname', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="standard"
                          label="Telefon"
                          fullWidth
                          defaultValue={d.ecoManagerPhone}
                          onBlur={(e) => changeDetail(d, 'ecoManagerPhone', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="standard"
                          label="Email"
                          fullWidth
                          defaultValue={d.ecoManagerEmail}
                          onBlur={(e) => changeDetail(d, 'ecoManagerEmail', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="standard"
                          label="Jméno"
                          fullWidth
                          defaultValue={d.marketingManagerName}
                          onBlur={(e) => changeDetail(d, 'marketingManagerName', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="standard"
                          label="Příjmení"
                          fullWidth
                          defaultValue={d.marketingManagerSurname}
                          onBlur={(e) => changeDetail(d, 'marketingManagerSurname', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="standard"
                          label="Telefon"
                          fullWidth
                          defaultValue={d.marketingManagerPhone}
                          onBlur={(e) => changeDetail(d, 'marketingManagerPhone', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="standard"
                          label="Email"
                          fullWidth
                          defaultValue={d.marketingManagerEmail}
                          onBlur={(e) => changeDetail(d, 'marketingManagerEmail', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    {
                      [
                        products.filter(
                          (p) =>
                            p.supplier?.toString() === d.id.toString() &&
                            p.heurekaStatus === 'best' &&
                            p.isObsolete !== true
                        ).length,
                      ].map((cnt) =>
                        cnt === 1 ? `1 produkt` : cnt > 1 && cnt < 5 ? `${cnt} produkty` : `${cnt} produktů`
                      )[0]
                    }
                    <br />
                    <span style={{ color: 'grey' }}>
                      {Math.round(
                        (100 *
                          products.filter(
                            (p) =>
                              p.supplier?.toString() === d.id.toString() &&
                              p.heurekaStatus === 'best' &&
                              p.isObsolete !== true
                          ).length) /
                          products.filter((p) => p.supplier?.toString() === d.id.toString() && p.isObsolete !== true)
                            .length
                      )}{' '}
                      % portfolia firmy
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow style={{ borderTop: 'none' }}>
                  <TableCell>
                    <TextField
                      fullWidth
                      label="Název v pharmosu"
                      defaultValue={d.pharmosName}
                      onBlur={(e) => changeDetail(d, 'pharmosName', e.target.value)}
                    />
                  </TableCell>
                  <TableCell style={{ borderTop: 'none' }} colSpan={4}>
                    <TextField
                      fullWidth
                      label="Poznámka"
                      defaultValue={d.remark}
                      onBlur={(e) => changeDetail(d, 'remark', e.target.value)}
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
      <Pagination
        count={maxPage}
        showFirstButton
        showLastButton
        page={page + 1}
        onChange={(_, val) => setPage(val - 1)}
        color="primary"
      />
    </>
  );
}
