import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { Order, OrderPreview } from '../Types/order';
import { apiUrl, catalogService, imagesService, ordersService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import OrderStatusLabel from '../Components/OrderStatusLabel';
import { Cancel, CheckCircle } from '@mui/icons-material';
import NextOrderStatusBtn from '../Components/NextOrderStatusBtn';
import { Pagination } from '@mui/material';
import OrdersFilters, { IFilter, emptyFilter, filterOrders } from './Modals/OrdersFilters';
import { getKeyExtractorReversedComparer } from '../Utils/Comparers';
import { round, toCzechTime } from '../Utils/Utils';
import { ShippingPaymentConfiguration, OrderState } from '@magistrmartin/eshop-frontend-shared';
import { generateOrderPdf } from '../Utils/PdfOrderGenerator';
import { IProduct, PickupPlace } from '@magistrmartin/eshop-frontend-shared';
import { createStyles, makeStyles } from '@mui/styles';
import RejectOrderModal from './Modals/RejectOrderModal';
import { domains } from '../Utils/Mutations';
import SendOrderDelayedMailModal from './Modals/SendOrderDelayedMailModal';
import useAllOrders from '../Hooks/Orders/useAllOrders';
import { exportOrders } from '../Utils/ProductsExport';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pointer: { cursor: 'pointer' },
    right: { float: 'right' },
    search: {
      flexGrow: 1,
      marginLeft: 16,
      marginRight: 16,
    },
    link: {
      color: theme.palette.text.primary,
    },
  })
);

export const getTotalGain = (o: OrderPreview) =>
  o.productsTotalGains - o.productsTotalCosts + o.additionalGains - o.additionalCosts;

const getPacketaLabel = (o: Order) => {
  const url = apiUrl + 'orders/packeta/printLabel?orderId=' + o.id;
  window.open(url, '_blank');
};

export default function OrdersHistory() {
  const classes = useStyles();

  const [selectedPlaceId, setSelectedPlaceId] = useState<number>(3);
  const [places, setPlaces] = useState<PickupPlace[]>([]);

  const [selectedChildRow, setSelectedChildRow] = useState<number | undefined>(undefined);
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>(undefined);
  const [filter, setFilter] = useState<IFilter>(emptyFilter);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [deliveryPaymentConfig, setDeliveryPaymentConfig] = useState<ShippingPaymentConfiguration | undefined>(
    undefined
  );
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);
  const { orders, isLoading: areOrdersLoading, setOrders } = useAllOrders();

  const layout = useLayout();

  areOrdersLoading ? layout.setIsLoading(true) : layout.setIsLoading(false);

  const changeChildRow = (id: number | undefined) => {
    if (id !== selectedChildRow) {
      setSelectedChildRow(id);
      setSelectedOrder(undefined);
      if (id !== undefined) {
        ordersService.get(
          '/noauth',
          { orderId: id },
          {
            success: setSelectedOrder,
            error: () => layout.error('Při načítání objednávky došlo k chybě'),
          }
        );
      }
    } else {
      setSelectedChildRow(undefined);
      setSelectedOrder(undefined);
    }
  };

  useEffect(() => {
    catalogService.get(
      '/products/all',
      { fields: ['id', 'productMass'] },
      {
        success: setAllProducts,
        error: () => layout.error('Při načítání hmotností produktů došlo k chybě'),
      }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      imagesService.get(
        '/text',
        {
          file: domains[0].replace('.', '_') + '_shippingPayment.json',
          location: 'config',
        },
        {
          success: setDeliveryPaymentConfig,
          error: () => layout.error('Při načítání cen dopravy a platby došlo k chybě'),
        }
      );
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      ordersService.get(
        '/infrastructure/pickupPlaces',
        {},
        {
          success: setPlaces,
          error: () => layout.error('Při načítání odběrových míst došlo k chybě'),
        }
      );
    },
    // eslint-disable-next-line
    []
  );

  const rowsPerPage = 25;

  const searchFilter = (o: OrderPreview) => {
    const s = search.toLowerCase();
    const affectedCols = [o.id?.toString() || '', o.name || '', o.surname || ''];
    return affectedCols.reduce((acc, act) => acc || act.toLowerCase().includes(s), false);
  };

  let filteredOrders = orders || [];
  // filteredOrders = filteredOrders.filter((o) => (o.deliveryType === "personalPickup" && o.deliveryInfo.packetaPlaceId === selectedPlaceId) || (selectedPlaceId === 3 && o.deliveryInfo.deliveryType !== "personalPickup"));

  filteredOrders = filterOrders(orders, filter);

  if (search.length > 0) filteredOrders = filteredOrders.filter(searchFilter);

  filteredOrders = filteredOrders.sort(getKeyExtractorReversedComparer('id'));

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={9} item>
          <Autocomplete
            value={{
              id: selectedPlaceId,
              label: places.find((p) => p.id === selectedPlaceId)?.name,
            }}
            onChange={(e, val) => val?.id && setSelectedPlaceId(val.id)}
            options={places.map((p) => ({ id: p.id, label: p.name }))}
            fullWidth
            isOptionEqualToValue={(v, o) => v.id === o.id}
            renderInput={(p) => <TextField label="Lékárna" {...p} />}
          />
        </Grid>
        <Grid xs={12} md={3} item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => deliveryPaymentConfig && exportOrders(orders, deliveryPaymentConfig)}
            fullWidth
          >
            Exportovat do excelu
          </Button>
        </Grid>
      </Grid>
      <Toolbar>
        <OrdersFilters filter={filter} setFilter={setFilter} />
        <TextField
          variant="standard"
          margin="dense"
          className={classes.search}
          label="Vyhledat"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Pagination
          count={Math.ceil(filteredOrders.length / rowsPerPage)}
          showFirstButton
          showLastButton
          page={page}
          onChange={(_, val) => setPage(val)}
          color="primary"
        />
      </Toolbar>
      <br />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Klient</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Datum objednávky</TableCell>
            <TableCell>Doprava</TableCell>
            <TableCell>Platba</TableCell>
            <TableCell>Hmotnost</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Zaplaceno</TableCell>
            <TableCell>Cena</TableCell>
            <TableCell>Zisk</TableCell>
            <TableCell>Marže</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredOrders
            .slice((page - 1) * rowsPerPage, Math.min(rowsPerPage * page, filteredOrders.length))
            .map((o) => (
              <React.Fragment key={o.id || -1}>
                <TableRow className={classes.pointer} onClick={() => changeChildRow(o.id)}>
                  <TableCell>
                    {o.domain}
                    <br />
                    {o.name} {o.surname}
                  </TableCell>
                  <TableCell>{o.id}</TableCell>
                  <TableCell>
                    {toCzechTime(new Date(o.createdDate || ''))
                      .toLocaleString('cs-CZ', { weekday: 'short' })
                      .toLocaleUpperCase()}{' '}
                    {toCzechTime(new Date(o.createdDate || '')).toLocaleDateString('cs-CZ')}
                    <br />
                    {toCzechTime(new Date(o.createdDate || '')).toLocaleTimeString('cs-CZ')}
                  </TableCell>
                  <TableCell>
                    {
                      //@ts-ignore
                      deliveryPaymentConfig?.shippingPrices[o.deliveryType]?.label
                    }
                    <br />
                    {o.deliveryPrice} Kč
                  </TableCell>
                  <TableCell>
                    {
                      //@ts-ignore
                      deliveryPaymentConfig?.paymentPrices[o.paymentType].label
                    }
                    <br />
                    {o.paymentPrice} Kč
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{o.mass} g</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <OrderStatusLabel orderStatus={o.status as OrderState} />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {o.paid && o.status && !["Canceled", "Rejected", "Refunded"].includes(o.status as string) ? (
                    <CheckCircle color={"primary"} />
                    ) : (
                      <Cancel color={o.paymentType === 'pickup' ? 'disabled' : 'error'} />
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{o.totalPrice?.toLocaleString('cs-CZ')}&nbsp;Kč</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {round(getTotalGain(o), 0).toLocaleString('cs-CZ')}&nbsp;Kč
                    {o.paidWithCard === false &&
                      o.deliveryType !== 'personalPickup' &&
                      o.paymentType === 'pickup' &&
                      o.status !== 'Refunded' && (
                        <>
                          <br />
                          <span style={{ color: 'green' }}>
                            +{' '}
                            {round(
                              (o.totalPrice || 0) *
                                0.79 *
                                0.01 *
                                (deliveryPaymentConfig?.paymentCosts.pickup.packeta.relative ?? 0),
                              2
                            )}
                            &nbsp;Kč
                          </span>
                        </>
                      )}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {round((100 * getTotalGain(o)) / (o.productsTotalGains + o.additionalGains), 2).toLocaleString(
                      'cs-CZ'
                    )}
                    &nbsp;%
                  </TableCell>
                </TableRow>
                {selectedChildRow === o.id && (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                          <Typography variant="h6">Fakturační údaje</Typography>
                          <br />
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <b>Jméno:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.name : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Příjmení:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.surname : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Ulice:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.street : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Číslo popisné:</b>&nbsp;&nbsp;&nbsp;&nbsp;
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.houseNum : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Město:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.city : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>PSČ:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.zip : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Firma:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.company : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>IČO:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.companyId : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>DIČ:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.vat : <Skeleton />}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="h6">Dodací údaje</Typography>
                          <br />
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <b>Email:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.email : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Telefon:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.phone : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Jméno:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.delName : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Příjmení:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.delSurname : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Ulice:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.delStreet : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Číslo popisné:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.delHouseNum : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Město:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.delCity : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>PSČ:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.delZip : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Firma:</b>
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.delComplany : <Skeleton />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Telefon pro kurýra:</b>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                </td>
                                <td>{selectedOrder ? selectedOrder.deliveryInfo.delPhone : <Skeleton />}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="h6">Historie vývoje objednávky</Typography>
                          <br />
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <OrderStatusLabel orderStatus="Waiting" />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </td>
                                <td>{toCzechTime(new Date(o.createdDate || '')).toLocaleString('cs-CZ')}</td>
                              </tr>
                              {selectedOrder &&
                                selectedOrder.statusChangeHistoryList.map((h) => (
                                  <tr key={h.id}>
                                    <td>
                                      <OrderStatusLabel orderStatus={h.newState as OrderState} />
                                    </td>
                                    <td>
                                      {h.newState === 'InProgress'
                                        ? toCzechTime(new Date(h.date || '')).toLocaleString('cs-CZ')
                                        : new Date(h.date || '').toLocaleString('cs-CZ')}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </Grid>
                        <Grid item xs={12}>
                          {selectedOrder && selectedOrder.deliveryInfo.deliveryType === 'packeta' && (
                            <span style={{ display: 'flex' }}>
                              <Typography>Odkaz na odběrové místo: </Typography>
                              &nbsp;&nbsp;
                              <a
                                className={classes.link}
                                href={selectedOrder.deliveryInfo.packetaPlaceUrl}
                                rel="noreferrer"
                                target="_blank"
                              >
                                {selectedOrder.deliveryInfo.packetaPlaceUrl}
                              </a>
                              <br />
                              <br />
                            </span>
                          )}
                          <Typography variant="h6">Objednané produkty</Typography>
                          <br />
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Hlavní název</TableCell>
                                <TableCell>Vedlejší název</TableCell>
                                <TableCell>Množství</TableCell>
                                <TableCell>Cena za ks</TableCell>
                                <TableCell>Celková cena</TableCell>
                                <TableCell>Hmotnost</TableCell>
                                <TableCell>Marže</TableCell>
                                <TableCell>Dostupnost</TableCell>
                                {o.domain === 'magistrmartin.cz' && <TableCell>Poznámka</TableCell>}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedOrder &&
                                selectedOrder.orderedProductsList.map((p) => (
                                  <TableRow key={p.productId}>
                                    <TableCell
                                      style={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => window.open(`/products/edit/${p.productId}`, '_blank')}
                                    >
                                      {p.productId}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        window.open(`https://magistrmartin.cz/product/${p.productId}`, '_blank')
                                      }
                                    >
                                      {p.productTitle}
                                    </TableCell>
                                    <TableCell>{p.productSubtitle}</TableCell>
                                    <TableCell>
                                      {p.totalAmmount} {(p.freeAmmount || 0) > 0 && ` (z toho ${p.freeAmmount} zdarma)`}
                                    </TableCell>
                                    <TableCell>
                                      {((p.finalPrice || 0) / (p.totalAmmount || 1)).toLocaleString('cs-CZ')} Kč
                                    </TableCell>
                                    <TableCell>{p.finalPrice?.toLocaleString('cs-CZ')} Kč</TableCell>
                                    <TableCell>
                                      {allProducts.find((pp) => pp.id === p.productId)?.productMass || 0} g
                                    </TableCell>
                                    <TableCell>
                                      {round(p.totalGains - p.totalCosts, 0).toLocaleString('cs-CZ')} Kč (
                                      {round((100 * (p.totalGains - p.totalCosts)) / p.totalGains, 2).toLocaleString(
                                        'cs-CZ'
                                      )}{' '}
                                      %)
                                    </TableCell>
                                    <TableCell>{p.availibilityRemark}</TableCell>
                                    {o.domain === 'magistrmartin.cz' && <TableCell>{p.remark}</TableCell>}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </Grid>
                        <Grid item xs={12}>
                          <Toolbar className={classes.right}>
                            {['InProgress', 'Shipped', 'Finished', 'Prepared', 'Refunded'].includes(o.status || '') &&
                              o.deliveryType !== 'personalPickup' && (
                                <>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                      window.open(
                                        'https://tracking.packeta.com/cs_CZ/?id=' +
                                          selectedOrder?.deliveryInfo?.packetId,
                                        '_blank'
                                      )
                                    }
                                  >
                                    Track&Trace Zásilkovna{' '}
                                  </Button>
                                  &nbsp; &nbsp;
                                </>
                              )}
                            {o.status === 'InProgress' && o.deliveryType !== 'personalPickup' && (
                              <>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => selectedOrder && getPacketaLabel(selectedOrder)}
                                >
                                  Vytisknout štítek Zásilkovny
                                </Button>
                                &nbsp; &nbsp;
                              </>
                            )}
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => selectedOrder && generateOrderPdf(selectedOrder, deliveryPaymentConfig)}
                            >
                              Stáhnout objednávku jako pdf
                            </Button>
                            &nbsp;&nbsp;
                            {!o.paid && o.status !== 'Rejected' && o.status !== 'Canceled' && (
                              <Button
                                onClick={() =>
                                  layout.confirm(
                                    'Nastavit objednávku jako zaplacenou',
                                    'Opravdu chcete nastavit tuto objednávku jako zaplacenou?',
                                    () =>
                                      ordersService.post('/setPaid/', { orderId: o.id }, null, {
                                        success: () =>
                                          setOrders(
                                            orders.map((ord) => (ord.id === o.id ? { ...o, paid: true } : ord))
                                          ),
                                        error: () => layout.error('Při ukládání došlo k chybě'),
                                      })
                                  )
                                }
                                color="primary"
                                variant="contained"
                              >
                                Nastavit jako zaplaceno
                              </Button>
                            )}
                            &nbsp;&nbsp;
                            {o.status === 'Waiting' ||
                              (o.status === 'Canceled' && <SendOrderDelayedMailModal orderId={o.id || 0} />)}
                            &nbsp;&nbsp;
                            <NextOrderStatusBtn
                              isPersonal={o.deliveryType === 'personalPickup'}
                              status={o.status}
                              setStatus={(newState) =>
                                layout.confirm('Změna statusu', 'Opravdu chcete změnit status objednávky?', () =>
                                  ordersService.post('/changeStatus/', { orderId: o.id, newStatus: newState }, null, {
                                    success: () => {
                                      if (
                                        newState === 'InProgress' &&
                                        selectedOrder &&
                                        selectedOrder.deliveryInfo.email?.toLowerCase() === 'senior'
                                      ) {
                                        layout.warning(
                                          'Tato obednávka byla vytvořena pomocí služby Senior linka. V tomto případě nebude zákazníkovi odeslán žádný email a je tedy nutné seniorovi zavolat a informovat ho o možnosti vyzvednutí objednávky.'
                                        );
                                      }
                                      setOrders(
                                        orders.map((ord) => (ord.id === o.id ? { ...o, status: newState } : ord))
                                      );
                                      if (o.deliveryType === 'personalPickup' && !o.paid && newState === 'Finished') {
                                        ordersService.post('/setPaid/', { orderId: o.id }, null, {
                                          success: () =>
                                            setOrders(
                                              orders.map((ord) =>
                                                ord.id === o.id
                                                  ? {
                                                      ...o,
                                                      status: newState,
                                                      paid: true,
                                                    }
                                                  : ord
                                              )
                                            ),
                                          error: () => layout.error('Při ukládání došlo k chybě'),
                                        });
                                      }
                                    },
                                    error: () => layout.error('Při ukládání došlo k chybě'),
                                  })
                                )
                              }
                            />
                            &nbsp;&nbsp;
                            {(o.status === 'Waiting' || o.status === 'InProgress') && selectedOrder && (
                              <RejectOrderModal
                                order={selectedOrder}
                                onReject={() =>
                                  setOrders(orders.map((ord) => (ord.id === o.id ? { ...o, status: 'Rejected' } : ord)))
                                }
                              />
                            )}
                          </Toolbar>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
      &nbsp;
      <Pagination
        count={Math.ceil(filteredOrders.length / rowsPerPage)}
        showFirstButton
        showLastButton
        page={page}
        onChange={(_, val) => setPage(val)}
        color="primary"
      />
      &nbsp;
    </>
  );
}
