interface FetchOptions {
  success: (data: any) => void;
  error: (message: string) => void;
  finally?: () => void;
}

const formatUrl = (url: string, params?: { [index: string]: any }) => {
  if (params === undefined) return url;
  return `${url}?${Object.keys(params)
    .filter((k) => params[k] !== null && params[k] !== undefined)
    .map((k) => {
      if (Array.isArray(params[k])) {
        return params[k].map((p: any) => `${k}=${encodeURIComponent(p.toString())}`).join('&');
      }
      return `${k}=${encodeURIComponent(params[k].toString())}`;
    })
    .join('&')}`;
};

class ApiService {
  private _apiUrl: string = '';

  public Init(apiUrl: string) {
    this._apiUrl = apiUrl;
  }

  public async get(url: string, params?: { [index: string]: any }, options?: FetchOptions) {
    if (url.startsWith('/')) url = url.substring(1);
    const initReq: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        // Authorization:
        //   localStorage.getItem("AT") === null ? "" : `Bearer ${localStorage.getItem("AT")}`,
      },
    };
    basicFetch(initReq, `${this._apiUrl}${url}`, params, options);
  }

  public async post(url: string, params: { [index: string]: any }, data?: any, options?: FetchOptions) {
    if (url.startsWith('/')) url = url.substring(1);
    const initReq: RequestInit = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        // Authorization:
        //   localStorage.getItem("AT") === null ? "" : `Bearer ${localStorage.getItem("AT")}`,
      },
      body: JSON.stringify(data),
    };
    basicFetch(initReq, `${this._apiUrl}${url}`, params, options);
  }

  public async postForText(url: string, params: { [index: string]: any }, data?: any, options?: FetchOptions) {
    if (url.startsWith('/')) url = url.substring(1);
    const initReq: RequestInit = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        // Authorization:
        //   localStorage.getItem("AT") === null ? "" : `Bearer ${localStorage.getItem("AT")}`,
      },
      body: JSON.stringify(data),
    };
    basicFetch(initReq, `${this._apiUrl}${url}`, params, options, true);
  }

  public async put(url: string, params?: { [index: string]: any }, data?: any, options?: FetchOptions) {
    if (url.startsWith('/')) url = url.substring(1);
    const initReq: RequestInit = {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        // Authorization:
        //   localStorage.getItem("AT") === null ? "" : `Bearer ${localStorage.getItem("AT")}`,
      },
      body: JSON.stringify(data),
    };

    basicFetch(initReq, `${this._apiUrl}${url}`, params, options);
  }

  public async delete(url: string, params?: { [index: string]: any }, data?: any, options?: FetchOptions) {
    if (url.startsWith('/')) url = url.substring(1);
    const initReq: RequestInit = {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        // Authorization:
        //   localStorage.getItem("AT") === null ? "" : `Bearer ${localStorage.getItem("AT")}`,
      },
      body: JSON.stringify(data),
    };

    basicFetch(initReq, `${this._apiUrl}${url}`, params, options);
  }

  public async uploadFile(
    url: string,
    file: File,
    params?: { [index: string]: any },
    body?: any,
    options?: FetchOptions
  ) {
    if (url.startsWith('/')) url = url.substring(1);
    const formData = new FormData();
    formData.append('file', file);
    if (body) {
      formData.append('body', JSON.stringify(body));
    }
    fetch(formatUrl(`${this._apiUrl}${url}`, params), {
      method: 'post',
      body: formData,
    }).then((res) => {
      if (res.ok) {
        res
          .text()
          .then((t) => {
            options?.success(t);
            (options?.finally || (() => {}))();
          })
          .catch(() => {
            options?.error('');
            (options?.finally || (() => {}))();
          });
      } else {
        options?.error(res.statusText);
        (options?.finally || (() => {}))();
      }
    });
  }
}

function basicFetch(
  initReq: RequestInit,
  url: string,
  params?: { [index: string]: any },
  options?: FetchOptions,
  textResponse?: boolean
) {
  if (options === undefined) options = { error: () => {}, success: () => {} };

  return new Promise(async (resolve, reject) => {
    fetch(formatUrl(url, params), initReq)
      .then((res) => {
        if (res.ok) {
          if (textResponse === true) {
            res
              .text()
              .then((result) => {
                options?.success(result);
                (options?.finally || (() => {}))();
                resolve(result);
              })
              .catch(() => {
                options?.success(null);
                (options?.finally || (() => {}))();
                resolve(null);
              });
          } else {
            res
              .json()
              .then((result) => {
                options?.success(result);
                (options?.finally || (() => {}))();
                resolve(result);
              })
              .catch(() => {
                options?.success(null);
                (options?.finally || (() => {}))();
                resolve(null);
              });
          }
        } else {
          options?.error(res.statusText + ' [' + res.status + ']');
          (options?.finally || (() => {}))();
          resolve(res.statusText + ' [' + res.status + ']');
        }
      })
      .catch((error) => {
        options?.error(error.toString());
        (options?.finally || (() => {}))();
        reject(error.toString());
      });
  });
}

const apiUrl = 'https://api.magistrmartin.cz/';

const ordersService = new ApiService();
const catalogService = new ApiService();
const imagesService = new ApiService();
const webStructureService = new ApiService();
const profilesService = new ApiService();
const statisticsService = new ApiService();
const notificationsService = new ApiService();

ordersService.Init(apiUrl + 'orders/');
catalogService.Init(apiUrl + 'catalog/');
imagesService.Init(apiUrl + 'images/');
profilesService.Init(apiUrl + 'profiles/');
webStructureService.Init(apiUrl + 'structure/');
statisticsService.Init(apiUrl + 'statistics/');
notificationsService.Init(apiUrl + 'notifications/');

export {
  ordersService,
  catalogService,
  imagesService,
  webStructureService,
  profilesService,
  apiUrl,
  statisticsService,
  notificationsService,
};
