import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Checkbox,
  Typography,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  IconButton,
  FormControlLabel,
} from '@mui/material';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import PriceInput from '../../Components/PriceInput';
import CreateDiscount from '../Modals/CreateDiscount';
import { IBaseDiscount, PickupPlace } from '@magistrmartin/eshop-frontend-shared';
import {
  getAbsoluteMargins,
  getAllDiscounts,
  getBasedRelativeMargins,
  getDiscountNameCZ,
  getMarginsColor,
  getRelativeMargins,
  sellingPrice,
  round,
  getHeurekaPrice,
  getHeurekaMarginsColor,
} from '../../Utils/Utils';
import DiscountCard from '../../Components/DiscountCard';
import LinkDiscount from '../Modals/LinkDiscount';
import { getKeyExtractorComparer } from '../../Utils/Comparers';
import { IProductStamp } from './EditProduct';
import { catalogService, ordersService } from '../../Utils/ApiService';
import { useLayout } from '../../Layout/LayoutContext';
import { createStyles, makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers';
import SalesHistoryModal from '../../Components/SalesHistoryModal';
import { Visibility } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemRoot: {
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  })
);

type ImportCorrrection = {
  placeId: number;
  importedId: number;
  idInEshop: number;
};

interface IProps {
  product: IProduct;
  changeProduct: (attr: keyof IProduct, val: any) => void;
  setProduct: (prd: IProduct) => void;
  allProducts: IProductStamp[];
}

const testingPharmaId = 5;

export default function PricesEditor({ product, changeProduct, allProducts, setProduct }: IProps) {
  const emptyCorrection = {
    placeId: -1,
    importedId: 0,
    idInEshop: product.id,
  };
  const classes = useStyles();
  const [pickupPlaces, setPickupPlaces] = useState<PickupPlace[]>([]);
  const [importCorrections, setImportCorrections] = useState<ImportCorrrection[]>([]);
  const [addCorrectionOpened, setAddCorrectionOpened] = useState(false);
  const [newCorrection, setNewCorrection] = useState<ImportCorrrection>(emptyCorrection);
  const [historySelectedItem, setHistorySelectedItem] = useState<IProduct | undefined>(undefined);
  const layout = useLayout();

  useEffect(() => {
    ordersService.get(
      'infrastructure/admin/allPickupPlaces',
      {},
      {
        success: setPickupPlaces,
        error: console.log,
      }
    );
  }, []);

  useEffect(() => {
    catalogService.get(
      '/stock/importCorrectionsByProduct',
      { productId: product.id },
      {
        success: setImportCorrections,
        error: () => console.log,
      }
    );
  }, [product.id]);

  const addCorrection = () => {
    catalogService.post('/stock/importCorrection', {}, newCorrection, {
      success: () => {
        setImportCorrections([...importCorrections, { ...newCorrection }]);
        setNewCorrection(emptyCorrection);
        setAddCorrectionOpened(false);
      },
      error: () => layout.error('Při ukládání došlo k chybě.'),
    });
  };

  const allDiscounts = getAllDiscounts(product);

  const saveDiscount = (d: IBaseDiscount) => {
    switch (d.type) {
      case 'FREE_DELIVERY':
        changeProduct('freeDelivery', d);
        break;
      case 'PRESENT':
        changeProduct('present', {
          ...d,
          targetId: parseInt(d.details as string),
        });
        break;
      case 'MIX':
        changeProduct('mixDiscount', {
          ...d,
          free: parseInt(d.details as string),
        });
        break;
      case 'SECOND_ITEM':
        changeProduct('secondItemDiscount', d);
        break;
      case 'SPECIAL':
        changeProduct('specialDiscount', d);
        break;
      case 'CROSS':
        changeProduct('crossDiscount', { ...d, connectedDiscount: d.discount });
        break;
    }
  };

  const ceilPrice = (price: number) =>
    product.isRegulated && product.pharmosReccomendedPrice && price > Math.floor(product.pharmosReccomendedPrice)
      ? Math.floor(product.pharmosReccomendedPrice)
      : price;

  return (
    <>
      <Grid container>
        {product.isRegulated && <Typography style={{ color: 'red' }}>CENA TOHOTO PRODUKTU JE REGULOVANÁ</Typography>}
        <Grid className={classes.itemRoot} item xs={12} md={6}>
          <PriceInput
            fullWidth
            label="Doporučená cena"
            value={product.reccomendedPrice}
            onChange={(val) => changeProduct('reccomendedPrice', ceilPrice(val))}
          />
          <br />
          <PriceInput
            fullWidth
            label="Cena v lékárně"
            value={product.physicalStorePrice}
            onChange={(val) => changeProduct('physicalStorePrice', val)}
          />
          <br />
          <br />
          <DatePicker
            inputFormat="yyyy-MM-dd"
            label="Neměnit akční cenu do"
            value={product.doNotChangeActionPriceUntil || null}
            onChange={(date) => changeProduct('doNotChangeActionPriceUntil', date || undefined)}
            renderInput={(p) => <TextField fullWidth {...p} margin="dense" />}
          />
        </Grid>
        <Grid className={classes.itemRoot} item xs={12} md={6}>
          <PriceInput
            fullWidth
            label="Akční cena"
            value={product.actionPrice}
            onChange={(val) =>
              changeProduct(
                'actionPrice',
                product.isRegulated && (product.reccomendedPrice || 0) > 0
                  ? Math.min(val, product.reccomendedPrice || 0)
                  : val
              )
            }
          />
          <br />
          <PriceInput fullWidth label="Doporučená cena Pharmos" value={product.pharmosReccomendedPrice} disabled />
          <br />
          <br />
          <Typography variant="caption" style={{ color: getMarginsColor(getRelativeMargins(product)) }}>
            Marže: {getAbsoluteMargins(product)} Kč, {getRelativeMargins(product)} %{' '}
          </Typography>
          <br />
          <Typography
            variant="caption"
            style={{
              color: getMarginsColor(getBasedRelativeMargins(product, product.physicalStorePrice || 0)),
            }}
          >
            Marže v lékárně:{' '}
            {round(getAbsoluteMargins(product) - sellingPrice(product) + (product.physicalStorePrice || 0), 2)} Kč,{' '}
            {getBasedRelativeMargins(product, product.physicalStorePrice || 0)} %
          </Typography>
          <br />
          <Typography
            variant="caption"
            style={{
              color: getHeurekaMarginsColor(getBasedRelativeMargins(product, getHeurekaPrice(product) || 0)),
            }}
          >
            Marže Heuréka: {round(getAbsoluteMargins(product) - sellingPrice(product) + getHeurekaPrice(product), 2)}{' '}
            Kč, {getBasedRelativeMargins(product, getHeurekaPrice(product) || 0)} %
          </Typography>
        </Grid>
        <Grid className={classes.itemRoot} item xs={12} md={6}>
          <PriceInput fullWidth label="Naše cena Heuréka" disabled value={getHeurekaPrice(product)} />
          <br />
          {product.heurekaStatus === 'paired' && <br />}
          <PriceInput
            fullWidth
            label="Ruční cena Heuréka"
            value={product.customHeurekaPrice}
            onChange={(val) => changeProduct('customHeurekaPrice', val)}
          />
        </Grid>
        <Grid className={classes.itemRoot} item xs={12} md={6}>
          <PriceInput
            fullWidth
            label="Nejnižší cena Heuréka"
            disabled
            value={product.heurekaPrice}
            onChange={(val) => changeProduct('heurekaPrice', val)}
          />
          <br />
          <br />
          <Typography variant="caption">Minimální PC Heuréka: {round(product.sellLimit || 0.0, 2)} Kč</Typography>
          <br />
          {product.heurekaStatus === 'paired' && (
            <>
              <Typography variant="caption">
                Konkurence levnější o {round((product.sellLimit || 0.0) - (product.heurekaPrice || 0), 0)} Kč,{' '}
                {round(100 - ((product.heurekaPrice || 0) / (product.sellLimit || 1)) * 100, 0)} %
              </Typography>
              <br />
            </>
          )}
          <Typography variant="caption">
            DPH: {product.vatRate !== undefined ? `${product.vatRate} %` : 'Informace nebyla importována'}
          </Typography>
        </Grid>
        <Grid className={classes.itemRoot} item xs={12} md={6}>
          <DatePicker
            inputFormat="yyyy-MM-dd"
            label="Expirace"
            value={product.customExpiration || null}
            onChange={(date) => changeProduct('customExpiration', date || undefined)}
            renderInput={(p) => <TextField fullWidth {...p} margin="dense" />}
          />
        </Grid>
        <Grid className={classes.itemRoot} item xs={12} md={6}>
          <Checkbox
            color="primary"
            checked={product.hideLowExpiration}
            onClick={() => changeProduct('hideLowExpiration', !product.hideLowExpiration)}
          />{' '}
          <Typography display="inline">Skrýt nízkou expiraci</Typography>
        </Grid>
      </Grid>

      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={product.isRegulated || product.isManualyRegulated}
            disabled={product.isRegulated}
          />
        }
        label="Regulovaná cena"
        onClick={() => changeProduct('isManualyRegulated', !product.isManualyRegulated)}
      />
      <FormControlLabel
        control={<Checkbox color="primary" checked={product.isFKSP} />}
        label="FKSP produkt"
        onClick={() => changeProduct('isFKSP', !product.isFKSP)}
      />
      <FormControlLabel
        control={<Checkbox color="primary" checked={product.isRx} />}
        label="Rx produkt"
        onClick={() => setProduct({ ...product, isRx: !product.isRx, published: false })}
      />
      <FormControlLabel
        control={<Checkbox color="primary" checked={product.isCzech} />}
        label="Produkt českého výrobce"
        onClick={() => changeProduct('isCzech', !product.isCzech)}
      />
      <br />
      <br />
      <Typography variant="h6">Další slevy</Typography>
      <br />
      {product.freeDelivery && (
        <>
          <DiscountCard
            discount={product.freeDelivery}
            title={getDiscountNameCZ(product.freeDelivery)}
            onDelete={() => changeProduct('freeDelivery', undefined)}
            onUnlink={() => changeProduct('freeDelivery', undefined)}
            onEdit={saveDiscount}
            productId={product.id}
          >
            <Typography>
              <b>Minimální nákup: </b>
              {product.freeDelivery.minimum?.value} {product.freeDelivery.minimum?.factor === 'items' ? 'ks' : 'Kč'}
            </Typography>
          </DiscountCard>
          <br />
        </>
      )}
      {product.present && (
        <>
          <DiscountCard
            discount={product.present}
            title={getDiscountNameCZ(product.present)}
            onDelete={() => changeProduct('present', undefined)}
            onUnlink={() => changeProduct('present', undefined)}
            onEdit={saveDiscount}
            productId={product.id}
          >
            <Typography>
              <b>Minimální nákup: </b>
              {product.present.minimum?.value} {product.present.minimum?.factor === 'items' ? 'ks' : 'Kč'}
              <br />
              <b>Id dárku: </b>
              {product.present.targetId}
              <br />
              <b>Typ slevy: </b> {product.present.single ? 'Jednorázová' : 'Opakovací'}
            </Typography>
          </DiscountCard>
          <br />
        </>
      )}
      {product.mixDiscount && (
        <>
          <DiscountCard
            discount={product.mixDiscount}
            title={getDiscountNameCZ(product.mixDiscount)}
            onDelete={() => changeProduct('mixDiscount', undefined)}
            onUnlink={() => changeProduct('mixDiscount', undefined)}
            onEdit={saveDiscount}
            productId={product.id}
          >
            <Typography>
              <b>Množství: </b> {product.mixDiscount.minimum?.value} + {product.mixDiscount.free} zdarma
            </Typography>
          </DiscountCard>
          <br />
        </>
      )}
      {product.secondItemDiscount && (
        <>
          <DiscountCard
            discount={product.secondItemDiscount}
            title={getDiscountNameCZ(product.secondItemDiscount)}
            onDelete={() => changeProduct('secondItemDiscount', undefined)}
            onUnlink={() => changeProduct('secondItemDiscount', undefined)}
            onEdit={saveDiscount}
            productId={product.id}
          >
            <Typography>
              <b>Sleva: </b> {product.secondItemDiscount.discount?.value}{' '}
              {product.secondItemDiscount.discount?.factor === 'relative' ? '%' : 'kč'}
            </Typography>
          </DiscountCard>
          <br />
        </>
      )}
      {product.specialDiscount && (
        <>
          <DiscountCard
            discount={product.specialDiscount}
            title={getDiscountNameCZ(product.specialDiscount)}
            onDelete={() => changeProduct('specialDiscount', undefined)}
            onUnlink={() => changeProduct('specialDiscount', undefined)}
            onEdit={saveDiscount}
            productId={product.id}
          >
            <Typography>
              <b>Minimální nákup: </b>
              {product.specialDiscount.minimum?.value}{' '}
              {product.specialDiscount.minimum?.factor === 'items' ? 'ks' : 'Kč'}
              <br />
              <b>Sleva: {}</b>
              {product.specialDiscount.discount?.value} Kč
            </Typography>
          </DiscountCard>
          <br />
        </>
      )}
      {product.crossDiscount && (
        <>
          <DiscountCard
            discount={product.crossDiscount}
            title={getDiscountNameCZ(product.crossDiscount)}
            onDelete={() => changeProduct('crossDiscount', undefined)}
            onUnlink={() => changeProduct('crossDiscount', undefined)}
            onEdit={saveDiscount}
            productId={product.id}
          >
            <Typography>
              <b>Minimální nákup ve skupině: </b>
              {product.crossDiscount.minimum?.value} ks
              <br />
              <b>Sleva: {}</b>
              {product.crossDiscount.discount?.value} Kč
              <br />
              <b>ID propojené slevová skupina: {}</b>
              {product.crossDiscount.connectedDiscount}
            </Typography>
          </DiscountCard>
          <br />
        </>
      )}
      <CreateDiscount
        productId={product.id}
        onSave={saveDiscount}
        forbiddenDiscounts={allDiscounts.map((d) => d.type)}
      />
      <br />
      <LinkDiscount onSave={saveDiscount} forbiddenDiscounts={allDiscounts.map((d) => d.type)} productId={product.id} />
      <br />
      <br />
      <br />
      <Typography style={{ display: 'flex' }}>
        Stavy na jednotlivých skladech <span style={{ flexGrow: 1 }}></span>{' '}
        <IconButton onClick={() => setHistorySelectedItem(product)}>
          <Visibility />
        </IconButton>
      </Typography>
      <Table>
        <TableBody>
          {pickupPlaces
            .filter((pp) => pp.id !== testingPharmaId)
            .map((pp) => (
              <TableRow key={pp.id}>
                <TableCell>{pp.name}</TableCell>
                <TableCell>
                  {(product.stockState?.filter((ss) => pp.id === ss.placeId).length || 0) > 0
                    ? product.stockState?.filter((ss) => pp.id === ss.placeId)[0].ammount || 0
                    : '-'}{' '}
                  ks
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell>Stav skladu Pharmos Brno</TableCell>
            <TableCell>{product.pharmosStockState || 0} ks</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
      {allProducts && (
        <Autocomplete
          value={{
            id: product.reccomendedAlternative?.alternativeId || 0,
            label:
              allProducts
                .filter((p) => p.id === product.reccomendedAlternative?.alternativeId)
                .map((p) => `${p.title} ${p.subtitle}`)[0] || '',
          }}
          onChange={(e, val) =>
            val
              ? setProduct({
                  ...product,
                  reccomendedAlternative: {
                    ...(product.reccomendedAlternative || { description: '' }),
                    alternativeId: val.id,
                  },
                })
              : setProduct({ ...product, reccomendedAlternative: undefined })
          }
          options={allProducts
            .filter((p) => p.id !== product.id)
            .sort(getKeyExtractorComparer('title'))
            .map((p) => ({
              id: p.id,
              label: `${p.title} ${p.subtitle || ''}`,
            }))}
          fullWidth
          isOptionEqualToValue={(o, v) => o.id === v.id}
          renderInput={(p) => <TextField label="Magistr Martin doporučuje" {...p} />}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id || -1}>
                {option.label}
              </li>
            );
          }}
        />
      )}
      <TextField
        variant="standard"
        fullWidth
        label="Výhody doporučeného produktu"
        multiline
        value={product.reccomendedAlternative?.description || ''}
        onChange={(e) =>
          changeProduct('reccomendedAlternative', {
            ...(product.reccomendedAlternative || {}),
            description: e.target.value,
          })
        }
      />
      <br />
      <br />
      <TextField
        variant="standard"
        fullWidth
        label="Maximální počet na košík"
        value={product.maxCartAmount || '0'}
        onChange={(e) => changeProduct('maxCartAmount', parseInt(e.target.value || '0'))}
      />
      <br />
      <br />
      <Typography>Historické/Alternativní PDK kódy</Typography>
      <br />
      {importCorrections.map((ic) => (
        <Chip
          key={`${ic.importedId}-${ic.placeId}`}
          color="primary"
          label={`${ic.importedId} ${
            (ic.placeId >= 0 && `(${pickupPlaces.filter((p) => p.id === ic.placeId)[0]?.name || ic.placeId})`) || ''
          }`}
          onDelete={() =>
            layout.deleteConfirm('Opravdu chcete smazat toto pravidlo?', 'Pravidlo bude trvale smazáno.', () =>
              catalogService.delete(
                '/stock/importCorrection',
                {
                  placeId: ic.placeId,
                  importedId: ic.importedId,
                },
                null,
                {
                  success: () =>
                    setImportCorrections(
                      importCorrections.filter((c) => c.importedId !== ic.importedId || c.placeId !== ic.placeId)
                    ),
                  error: () => layout.error('Při mazání došlo k chybě'),
                }
              )
            )
          }
        />
      ))}
      <Button color="primary" onClick={() => setAddCorrectionOpened(true)}>
        Přidat kód
      </Button>
      <Dialog open={addCorrectionOpened} onClose={() => setAddCorrectionOpened(false)} fullWidth>
        <DialogTitle title="Přidat historický/alternativní PDK" />
        <DialogContent style={{ overflowY: 'hidden' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <TextField
                variant="standard"
                fullWidth
                label="Alternativní PDK"
                type="number"
                margin="normal"
                onChange={(e) =>
                  setNewCorrection({
                    ...newCorrection,
                    importedId: parseInt(e.target.value),
                  })
                }
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
            <SearchableSelect
              label="Lékárna"
              value={newCorrection.placeId}
              onChange={(e) => setNewCorrection({ ...newCorrection, placeId: e.target.value as number })}
              options={pickupPlaces}
              formControlProps={{ fullWidth: true }}
              keyPropFn={(p) => p.id}
              valuePropFn={(p) => p.name}
            />
          </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setAddCorrectionOpened(false)}>
            Zavřít
          </Button>
          <Button
            color="primary"
            disabled={newCorrection.importedId <= 0 || newCorrection.idInEshop <= 0}
            onClick={addCorrection}
          >
            Uložit
          </Button>
        </DialogActions>
      </Dialog>
      <SalesHistoryModal product={historySelectedItem} placeId={0} onClose={() => setHistorySelectedItem(undefined)} />
    </>
  );
}
