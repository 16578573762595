import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Theme } from '@mui/material';
import {
  AccountTree,
  Apps,
  Brightness4,
  ExpandLess,
  ExpandMore,
  History,
  Home,
  ImportExport,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LineStyle,
  ListAlt,
  LocalShipping,
  MoneyOff,
  PhotoSizeSelectSmall,
  Settings,
  ShoppingBasket,
  ShowChart,
  WarningRounded,
  Web,
  Pageview,
  People,
  PriorityHigh,
  Store,
  WbAutoOutlined,
  Explicit,
  LocalConvenienceStore,
  PlaylistAddCheckRounded,
  AddShoppingCart,
  Visibility,
  EventRepeat,
  Receipt,
  ControlPointDuplicate,
  AttachMoney,
  Class,
} from '@mui/icons-material';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';

interface IProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  theme: 'dark' | 'light';
  changeAppTheme: (theme: 'dark' | 'light') => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'fixed',
      top: '73px',
      left: 0,
      backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#dedede',
      paddingTop: 0,
      paddingBottom: 0,
      zIndex: 999,
    },
    fixed: {
      width: '100%',
      height: '80px',
      position: 'fixed',
      bottom: '0',
      backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#dedede',
      zIndex: 999,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    changeThemeButton: {
      width: '100%',
      position: 'absolute',
      bottom: 48,
      left: 0,
    },
    collapseButton: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
    listContainer: {
    height: 'calc(100vh - 80px)',
    overflow: 'auto',

    },
  })
);

export default function Navbar({ opened, setOpened, theme, changeAppTheme }: IProps) {
  const classes = useStyles();
  const history = useHistory();
  const [openedCats, setOpenedCats] = useState<string[]>([]);

  return (
    <>
    <List component="nav" className={`${classes.root} ${classes.listContainer}`} style={{ maxWidth: opened ? '280px' : '72px' }}>
      <ListItemButton
        onClick={() => {
          if (openedCats.includes('orders')) setOpenedCats(openedCats.filter((c) => c !== 'orders'));
          else setOpenedCats([...openedCats, 'orders']);
        }}
      >
        <ListItemIcon>
          <ShoppingBasket />
        </ListItemIcon>
        {opened && <ListItemText primary="Objednávky" />}
        {opened && (openedCats.includes('orders') ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={openedCats.includes('orders')} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton className={classes.nested} onClick={() => history.push('/orders/history')}>
            {opened && <ListItemText primary="Přehled objednávek" />}
            <ListItemIcon>
              <History />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/reservations')}>
            {opened && <ListItemText primary="Rezervace eReceptu" />}
            <ListItemIcon>
              <Explicit />
            </ListItemIcon>
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton
        onClick={() => {
          if (openedCats.includes('stock')) setOpenedCats(openedCats.filter((c) => c !== 'stock'));
          else setOpenedCats([...openedCats, 'stock']);
        }}
      >
        <ListItemIcon>
          <LocalConvenienceStore />
        </ListItemIcon>
        {opened && <ListItemText primary="Sklad" />}
        {opened && (openedCats.includes('stock') ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={openedCats.includes('stock')} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton className={classes.nested} onClick={() => history.push('/automaticOrdering')}>
            {opened && <ListItemText primary="Automat. objednávání" />}
            <ListItemIcon>
              <WbAutoOutlined />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/orders/ao')}>
            {opened && <ListItemText primary="Přehled objednávek" />}
            <ListItemIcon>
              <WbAutoOutlined />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/freeItemsDelivery')}>
            {opened && <ListItemText primary="Rabaty navíc" />}
            <ListItemIcon>
              <PlaylistAddCheckRounded />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/toOrder')}>
            {opened && <ListItemText primary="Distribuce doobjednat" />}
            <ListItemIcon>
              <AddShoppingCart />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/supplierDetails')}>
            {opened && <ListItemText primary="Dodavatelé" />}
            <ListItemIcon>
              <LocalConvenienceStore />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/pharmosWatchList')}>
            {opened && <ListItemText primary="Pharmos WatchList" />}
            <ListItemIcon>
              <Visibility />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/invoiceReformatter')}>
            {opened && <ListItemText primary="Faktury Pohoda" />}
            <ListItemIcon>
              <Receipt />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/selloutBonus')}>
            {opened && <ListItemText primary="Bonusy" />}
            <ListItemIcon>
              <AttachMoney />
            </ListItemIcon>
          </ListItemButton>
          {/* <ListItem button className={classes.nested} onClick={() => history.push("/pdkRepair")}>
            {opened && <ListItemText primary="Oprava PDK v historii" />}
            <ListItemIcon>
              <Build />
            </ListItemIcon>
          </ListItem> */}
        </List>
      </Collapse>

      <ListItemButton
        onClick={() => {
          if (openedCats.includes('catalog')) setOpenedCats(openedCats.filter((c) => c !== 'catalog'));
          else setOpenedCats([...openedCats, 'catalog']);
        }}
      >
        <ListItemIcon>
          <ListAlt />
        </ListItemIcon>
        {opened && <ListItemText primary="Katalog" />}
        {opened && (openedCats.includes('catalog') ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={openedCats.includes('catalog')} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton className={classes.nested} onClick={() => history.push('/products')}>
            {opened && <ListItemText primary="Produkty" />}
            <ListItemIcon>
              <Apps />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/products/import')}>
            {opened && <ListItemText primary="Import/Export" />}
            <ListItemIcon>
              <ImportExport />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/categories')}>
            {opened && <ListItemText primary="Kategorie" />}
            <ListItemIcon>
              <AccountTree />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/discounts')}>
            {opened && <ListItemText primary="Slevy" />}
            <ListItemIcon>
              <MoneyOff />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/shippingConfig')}>
            {opened && <ListItemText primary="Doprava a platba" />}
            <ListItemIcon>
              <LocalShipping />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/phramas')}>
            {opened && <ListItemText primary="Registrované lékárny" />}
            <ListItemIcon>
              <Store />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/catalog/config')}>
            {opened && <ListItemText primary="Konfigurace" />}
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/catalog/tips')}>
            {opened && <ListItemText primary="Tipy na produkty" />}
            <ListItemIcon>
              <PriorityHigh />
            </ListItemIcon>
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton
        onClick={() => {
          if (openedCats.includes('anal')) setOpenedCats(openedCats.filter((c) => c !== 'anal'));
          else setOpenedCats([...openedCats, 'anal']);
        }}
      >
        <ListItemIcon>
          <ShowChart />
        </ListItemIcon>
        {opened && <ListItemText primary="Marketing" />}
        {opened && (openedCats.includes('anal') ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={openedCats.includes('anal')} timeout="auto" unmountOnExit>
        <ListItemButton className={classes.nested} onClick={() => history.push('/structure/home')}>
          {opened && <ListItemText primary="Marketingové akce" />}
          <ListItemIcon>
            <Home />
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton className={classes.nested} onClick={() => history.push('/categoryManagement')}>
          {opened && <ListItemText primary="Category management" />}
          <ListItemIcon>
            <Class />
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton className={classes.nested} onClick={() => history.push('/anal/heurekaPairing')}>
          {opened && <ListItemText primary="Párování Heuréka" />}
          <ListItemIcon>
            <ControlPointDuplicate />
          </ListItemIcon>
        </ListItemButton>
        <List component="div" disablePadding>
          <ListItemButton className={classes.nested} onClick={() => history.push('/anal/users')}>
            {opened && <ListItemText primary="Seznam uživatelů" />}
            <ListItemIcon>
              <People />
            </ListItemIcon>
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton className={classes.nested} onClick={() => history.push('/anal/searchHistory')}>
            {opened && <ListItemText primary="Historie vyhledávání" />}
            <ListItemIcon>
              <Pageview />
            </ListItemIcon>
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton
        onClick={() => {
          if (openedCats.includes('structure')) setOpenedCats(openedCats.filter((c) => c !== 'structure'));
          else setOpenedCats([...openedCats, 'structure']);
        }}
      >
        <ListItemIcon>
          <LineStyle />
        </ListItemIcon>
        {opened && <ListItemText primary="Struktura webu" />}
        {opened && (openedCats.includes('structure') ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={openedCats.includes('structure')} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton className={classes.nested} onClick={() => history.push('/structure/sites')}>
            {opened && <ListItemText primary="Statické Stránky" />}
            <ListItemIcon>
              <Web />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/structure/footer')}>
            {opened && <ListItemText primary="Patička" />}
            <ListItemIcon>
              <PhotoSizeSelectSmall />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/structure/notifications')}>
            {opened && <ListItemText primary="Oznámení" />}
            <ListItemIcon>
              <WarningRounded />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton className={classes.nested} onClick={() => history.push('/structure/scheduledJobs')}>
            {opened && <ListItemText primary="Pravidelné činnosti" />}
            <ListItemIcon>
              <EventRepeat />
            </ListItemIcon>
          </ListItemButton>
        </List>
      </Collapse>
      </List>

      <List component="nav" className={classes.fixed} style={{ maxWidth: opened ? '280px' : '72px' }}>
      <ListItemButton
        onClick={() => {
          changeAppTheme(theme === 'dark' ? 'light' : 'dark');
        }}
        className={classes.changeThemeButton}
      >
        <ListItemIcon>
          <Brightness4 />
        </ListItemIcon>
        {opened && <ListItemText primary={theme === 'dark' ? 'Light mode' : 'Dark mode'} />}
      </ListItemButton>

      <ListItemButton
        onClick={() => {
          setOpened(!opened);
        }}
        className={classes.collapseButton}
      >
        <ListItemIcon>{opened ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}</ListItemIcon>
        {opened && <ListItemText primary="Skrýt menu" />}
      </ListItemButton>
        </List>    
    </>
  );
}
