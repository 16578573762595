import React from 'react';
import { Autocomplete, Button, Chip, Grid, TextField } from '@mui/material';
import LoadProductsModal from '../Modals/LoadProductsModal';
import { HomeConfigType } from './HomeConfig';
import { IProductStamp } from '../EditProduct/EditProduct';
import { getKeyExtractorComparer } from '../../Utils/Comparers';
import { DatePicker } from '@mui/x-date-pickers';

interface IProps {
  config: HomeConfigType;
  setConfig: (newConifg: HomeConfigType) => void;
  allProducts: IProductStamp[];
}

export default function ActionSeries({ allProducts, config, setConfig }: IProps) {
  const findProduct = (id: number) => {
    const prd = allProducts?.filter((tp) => tp.id === id)[0] || { title: id.toString(), subtitle: '' };
    return `${prd.title} ${prd.subtitle}`;
  };

  return (
    <>
      {config.productSeries.map((s, i) => (
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField
              variant="standard"
              value={s.name}
              onChange={(e) =>
                setConfig({
                  ...config,
                  productSeries: config.productSeries.map((ss, ii) =>
                    i === ii ? { ...ss, name: e.target.value } : ss
                  ),
                })
              }
              fullWidth
              label="Název"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DatePicker
              inputFormat="yyyy-MM-dd"
              label="Platí od"
              value={s.validFrom || null}
              onChange={(date) =>
                setConfig({
                  ...config,
                  productSeries: config.productSeries?.map((ss, ii) =>
                    i === ii ? { ...ss, validFrom: date || undefined } : ss
                  ),
                })
              }
              renderInput={(p) => <TextField fullWidth {...p} />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DatePicker
              inputFormat="yyyy-MM-dd"
              label="Platí do"
              value={s.validTo || null}
              onChange={(date) =>
                setConfig({
                  ...config,
                  productSeries: config.productSeries?.map((ss, ii) =>
                    i === ii ? { ...ss, validTo: date || undefined } : ss
                  ),
                })
              }
              renderInput={(p) => <TextField fullWidth {...p} />}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              color="secondary"
              onClick={() => setConfig({ ...config, productSeries: config.productSeries.filter((ss, ii) => ii !== i) })}
            >
              Smazat
            </Button>
            {i > 0 && (
              <Button
                color="primary"
                onClick={() =>
                  setConfig({
                    ...config,
                    productSeries: config.productSeries.map((ss, ii, all) =>
                      ii === i - 1 ? all[i] : ii === i ? all[i - 1] : ss
                    ),
                  })
                }
              >
                Nahoru
              </Button>
            )}
            <LoadProductsModal
              onLoad={(products) =>
                setConfig({
                  ...config,
                  productSeries: config.productSeries.map((ss, ii) =>
                    ii === i
                      ? {
                          ...ss,
                          products: products,
                        }
                      : ss
                  ),
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            {s.products.map((tp) => (
              <Chip
                key={tp}
                label={findProduct(tp)}
                onDelete={() =>
                  setConfig({
                    ...config,
                    productSeries: config.productSeries.map((ss, ii) =>
                      i === ii ? { ...ss, products: ss.products.filter((p) => tp !== p) } : ss
                    ),
                  })
                }
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            {allProducts && (
              <Autocomplete
                value={{ id: 0, label: '' }}
                onChange={(e, val) =>
                  val?.id &&
                  setConfig({
                    ...config,
                    productSeries: config.productSeries.map((ss, ii) =>
                      i === ii ? { ...ss, products: [...ss.products, val.id] } : ss
                    ),
                  })
                }
                options={[
                  { id: 0, label: '' },
                  ...allProducts
                    .sort(getKeyExtractorComparer('title'))
                    .map((p) => ({ id: p.id, label: `${p.title} ${p.subtitle}` })),
                ]}
                isOptionEqualToValue={(o, v) => o.id === v.id}
                fullWidth
                renderInput={(p) => <TextField label="Přidat top produkt" {...p} />}
              />
            )}
          </Grid>
        </Grid>
      ))}
      <br />
      <Button
        color="primary"
        onClick={() =>
          setConfig({
            ...config,
            productSeries: [
              ...config.productSeries,
              {
                name: '',
                products: [],
              },
            ],
          })
        }
      >
        Přidat řadu
      </Button>
    </>
  );
}
