import { Button } from '@mui/material';
import React from 'react';
import { OrderState } from '@magistrmartin/eshop-frontend-shared';

interface IProps {
  status?: OrderState;
  isPersonal: boolean;
  setStatus: (newStatus: OrderState) => void;
}

export default function NextOrderStatusBtn({ setStatus, status, isPersonal }: IProps) {
  const successor = isPersonal
    ? status === 'Waiting'
      ? 'InProgress'
      : status === 'InProgress'
        ? 'Finished'
        : status === 'Shipped' || status === 'Prepared'
          ? 'Finished'
          : status === 'Canceled'
            ? 'InProgress'
            : status === 'Finished'
              ? 'Refunded'
              : undefined
    : status === 'Waiting'
      ? 'InProgress'
      : status === 'InProgress'
        ? 'Shipped'
        : status === 'Shipped' || status === 'Prepared'
          ? 'Finished'
          : status === 'Canceled'
            ? 'InProgress'
            : status === 'Finished'
              ? 'Refunded'
              : undefined;
  const successorText = isPersonal
    ? status === 'Waiting'
      ? 'Zabaleno'
      : status === 'InProgress'
        ? 'Uzavřeno'
        : status === 'Shipped' || status === 'Prepared'
          ? 'Uzavřeno'
          : status === 'Canceled'
            ? 'Zabaleno'
            : status === 'Finished'
              ? 'Vratka'
              : undefined
    : status === 'Waiting'
      ? 'Zabaleno'
      : status === 'InProgress'
        ? 'Odesláno'
        : status === 'Shipped' || status === 'Prepared'
          ? 'Uzavřeno'
          : status === 'Canceled'
            ? 'Zabaleno'
            : status === 'Finished'
              ? 'Vratka'
              : undefined;

  return successor ? (
    <Button variant="contained" color="primary" onClick={() => setStatus(successor)}>
      Nastavit status jako "{successorText}"
    </Button>
  ) : (
    <></>
  );
}
