import React from 'react';
import { Button, Grid, TextField, Theme, Typography } from '@mui/material';
import { useLayout } from '../../Layout/LayoutContext';
import { openFileContext } from '../../Utils/Utils';
import { HomeConfigType } from './HomeConfig';
import { createStyles, makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.text.primary,
    },
  })
);

interface IProps {
  config: HomeConfigType;
  setConfig: (newConifg: HomeConfigType) => void;
  getSaveFile: (key: 'banners' | 'leaflets') => (files?: File[]) => void;
}

export default function Leaflets({ config, setConfig, getSaveFile }: IProps) {
  const layout = useLayout();
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6">Akční letáky</Typography>
      <br />
      {config.leaflets &&
        (config.leaflets.length || 0) > 0 &&
        config.leaflets.map((l) => (
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography>
                <a className={classes.link} href={l.file} rel="noopener noreferrer" target="_blank">
                  {l.file.split('/').reverse()[0].substring(13)}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <DatePicker
                inputFormat="yyyy-MM-dd"
                label="Platí od"
                value={l.validFrom || null}
                onChange={(date) =>
                  setConfig({
                    ...config,
                    leaflets: config.leaflets?.map((ll) =>
                      ll.file === l.file ? { ...ll, validFrom: date || undefined } : ll
                    ),
                  })
                }
                renderInput={(p) => <TextField fullWidth {...p} />}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DatePicker
                inputFormat="yyyy-MM-dd"
                label="Platí do"
                value={l.validTo || null}
                onChange={(date) =>
                  setConfig({
                    ...config,
                    leaflets: config.leaflets?.map((ll) =>
                      ll.file === l.file ? { ...ll, validTo: date || undefined } : ll
                    ),
                  })
                }
                renderInput={(p) => <TextField fullWidth {...p} />}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                color="secondary"
                onClick={() =>
                  layout.deleteConfirm('Smazat leták', 'Opravdu chcete smazat tento leták?', () =>
                    setConfig({ ...config, leaflets: config.leaflets?.filter((ll) => ll.file !== l.file) })
                  )
                }
              >
                Smazat
              </Button>
            </Grid>
          </Grid>
        ))}
      <br />
      <Button variant="contained" color="primary" onClick={() => openFileContext(getSaveFile('leaflets'))}>
        Nahrát leták
      </Button>
    </>
  );
}
